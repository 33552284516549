import React from 'react';

import PageTransition from '../components/PageTransition';
import routes from '../messages/routes';
import SetNewPassword from '../views/ForgotPassword/SetNewPassword';
import { steps } from '../utils/routes';

const SetPasswordPage = () => (
  <PageTransition basepath={'/'} showStepper={false} hideMenu>
    <SetNewPassword path={routes[steps.SetNewPassword]} />
  </PageTransition>
);

SetPasswordPage.propTypes = {};

export default SetPasswordPage;
