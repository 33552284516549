import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from '@reach/router';
import { parse } from 'qs';

import Seo from '../../components/seo';
import messages from '../../views/Login/messages';
import Main from '../../components/Main';
import { Form, Formik } from 'formik';
import Card from '../../components/Card';
import StepTitle from '../../components/StepTitle';
import Alert from '../../components/Alert';
import Input from '../../components/fields/Input';
import Button from '../../components/Button';
import Container from '../../components/Container';
import {
  clearAllCookies,
  getResponseErrors,
  jsonApiAxios,
} from '../../services/api';
import * as yup from 'yup';
import useAuthenticate from '../../hooks/useAuthenticate';
import routes from '../../messages/routes';
import { steps, useTranslatedNavigate } from '../../utils/routes';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(6)
    .required(),
  password_confirmation: yup
    .string()
    .min(6)
    .required(),
});

const SetNewPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);
  const { doLogin } = useAuthenticate();
  const translatedNavigate = useTranslatedNavigate();
  const { search } = useLocation();

  const queryObject = parse(search, { ignoreQueryPrefix: true });
  const token = queryObject?.token;

  const { formatMessage } = useIntl();

  function handleSubmit(values, { setSubmitting }) {
    setErrors(false);
    setSuccess(false);
    setIsSubmitting(true);
    jsonApiAxios
      .post(`/passwords`, values, {
        noSerialize: true,
      })
      .then(() => {
        setSuccess(true);
        setSubmitting(false);
        setIsSubmitting(false);
        jsonApiAxios
          .post('/login', values, {
            noSerialize: true,
          })
          .then(() => {
            doLogin();
            translatedNavigate(['/', routes[steps.Account]]);
          })
          .catch(() => {
            clearAllCookies();
            setSubmitting(false);
          });
      })
      .catch(error => {
        setSuccess(false);
        setSubmitting(false);
        setIsSubmitting(false);
        if (error?.response) {
          setErrors(getResponseErrors(error));
        }
      });
  }

  return (
    <Container>
      <Seo
        title={messages.newPasswordSeoTitle}
        description={messages.newPasswordSeoDescription}
      />
      <Main className="m-auto sm:max-w-740">
        <Formik
          initialValues={{ token }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isValid }) => (
            <Form>
              <Card onlyDesktop>
                <StepTitle className={'sm:text-center'}>
                  {formatMessage(messages.newPasswordTitle)}
                </StepTitle>
                <Card onlyMobile>
                  <p className="mb-40">
                    {formatMessage(messages.newPasswordInfo)}
                  </p>
                  <div className="fieldset">
                    <Input
                      name="email"
                      label={formatMessage(messages.placeholderEmail)}
                      full
                      placeholder={formatMessage(messages.placeholderEmail)}
                    />
                    <Input
                      name="password"
                      label={formatMessage(messages.newPasswordLabel)}
                      full
                      type="password"
                      placeholder={formatMessage(
                        messages.placeholderNewPassword
                      )}
                    />
                    <Input
                      name="password_confirmation"
                      label={formatMessage(messages.confirmPasswordLabel)}
                      full
                      type="password"
                      placeholder={formatMessage(
                        messages.placeholderConfirmPassword
                      )}
                    />
                  </div>
                </Card>
                <div className="flex m-auto flex-col align-center items-center justify-center mx-8 sm:mx-0">
                  {success ? (
                    <Alert level="success" className="w-full my-20">
                      {formatMessage(messages.setPasswordSuccess)}
                    </Alert>
                  ) : errors?.length > 0 ? (
                    errors.map((error, index) => (
                      <Alert
                        // eslint-disable-next-line
                        key={`error-${index}`}
                        level="danger"
                        className="w-full my-20"
                      >
                        {error}
                      </Alert>
                    ))
                  ) : null}
                  <Button
                    theme="primary"
                    className="mt-25 sm:w-170"
                    isSubmitting={isSubmitting}
                    disabled={!isValid}
                  >
                    {formatMessage(messages.setPasswordButton)}
                  </Button>
                </div>
              </Card>
            </Form>
          )}
        </Formik>
      </Main>
    </Container>
  );
};

export default SetNewPassword;
